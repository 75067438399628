import React from 'react';
import './PassList.css';

const PassList = () => {
  /* Change this if you want to change any of your "top VPN" rankings items */
  const topPassMans = [
    {
      tName: ' NordPass',
      tRating: '9.8/10',
      tUrl: 'https://nordpass.com/',
      tImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfQpvdW_eaYGpIfdnKLoxxMZkc5xw9d8i9fw&s',
      tDescription: 'User Friendly and E2E encryption, known for its trust from NordVPN',
    },
    {
      tName: ' ProtonPass',
      tRating: '9.5/10',
      tUrl: 'https://proton.me/pass',
      tImg: 'https://upload.wikimedia.org/wikipedia/commons/3/34/ExpressVPN-logo.png',
      tDescription: 'Good value if you have Proton unlimited subscription',
    },
    {
      tName: ' Bitwarden',
      tRating: '9.1/10',
      tUrl: 'https://bitwarden.com/',
      tImg: 'https://play-lh.googleusercontent.com/X3V8MJMnYasSOTLeFAN-EX8vKrckT2kxnPSeKRyGEU77pa-PVOjvKSvMdcOhnHX9aEg',
      tDescription: 'Best free and open source password manager',
    },
    {
      tName: ' 1Password',
      tRating: '8.9/10',
      tUrl: 'https://1password.com/',
      tImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqCq2eDoP-ZDiWkTJlqMDipvtAtFSib7etJg&s',
      tDescription: 'Simple and easy to use',
    },
  ];

  return (
    <div className="tier-list">
      <img alt="VPN Guides Banner" className="logo-banner" src="https://i.e-z.host/iiuvfmip.png"></img>
        <div className="top-container">
        <div className="titles-container">
        <h3 className="main-heading">
          Why use VPN Guides?
        </h3>
        <p>
        We are 100% honest and upfront about everything we do. Every VPN we rank and list is a VPN that has been tried and tested! We are also fully open to feedback, so feel free 
        to give any feedback you may have, good or bad.
        </p>
      </div>
      </div>
      <h3>Our top PWMs:</h3>
      {topPassMans.map((topPassMans, index) => (
      <div className="top-vpn-container" key={index}>
        <h3>
          {index + 1 + ')'}
          <a href={topPassMans.tUrl} target="_blank" rel="noreferrer">
            {/*
              <img alt={topPassMans.tName} className="top-vpn-images" src={topPassMans.tImg} />
            */}
            {topPassMans.tName}
          </a>
        </h3>
        <p className="left-align">{topPassMans.tDescription}</p>
        <p className="right-align">Rating: {topPassMans.tRating}</p>
      </div>
    ))}
      <div className="updates-container">
        <h3>VPN Guides Updates - 1/2025:</h3>
        <p>Updated tier list order and top VPNs order</p>
        <p>----------------------------------------------------------------</p>
        <h3>VPN Guides Updates - 1/2025:</h3>
        <p>Updated VPN tier list and added a Password Manager tier list!</p>
        <p>----------------------------------------------------------------</p>
        <h3>VPN Guides Updates - 8/2024:</h3>
        <p>Initial release of the VPN Guides rankings!</p>
      </div>
    </div>
  );
};

export default PassList;
